import React from 'react';
import { Pagination, Spin, message } from 'antd';
// import Mansory from 'react-masonry-component';
//import Loader from 'react-loader-spinner';
import Loader from '../loader';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PageLayout from '../pageLayout/PageLayout';
import PostThumbnail from '../postThumbnail/PostThumbnail';
import './Home.scss';

const GET_POSTS = gql`
    query getPosts($pageIndex: Int!, $pageSize: Int!){
        posts(pageIndex: $pageIndex, pageSize: $pageSize){
            totalCount
            posts {
                _id
                title
                summary
                createdAt
                cover
                author {
                    username
                }
            }
        }
    }
`;

interface PostsData {
    posts: {
        totalCount: number
        posts: {
            _id: string
            title: string
            summary: string
            createdAt: string
            cover: string
            author: {
                username: string
            }
        }[]
    }
}

interface PostsVariables {
    pageIndex: number,
    pageSize: number
}

interface Props {
}

interface State extends PostsVariables {

}


class Home extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            pageIndex: 1,
            pageSize: 7
        };
    }

    handlePgaeChange = (value: number) => {
        this.setState({
            pageIndex: value
        })
    }

    render() {
        const { pageIndex, pageSize } = this.state;

        return (
            <PageLayout currentPage="home">
                <section className="sec-home">
                    <div className="intro-wrap animated fadeInDown">
                        <h1 className="title">Pomevak's Blog</h1>
                        <h2 className="description">This is a place of thoughts, ideas, and stories.</h2>
                    </div>
                    <Query<PostsData, PostsVariables>
                        query={GET_POSTS}
                        variables={{ pageIndex, pageSize }}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <Spin className="spin-wrap" indicator={<Loader type="Bars" color="#eb8a63" height="100" width="100" />} />;

                            if (error) return message.error("Something went wrong, failed to load!");

                            if (data) return (<>
                                {
                                    pageIndex === 1 && data.posts.posts.length > 0 &&
                                    <div className="featured-wrap animated fadeIn">
                                        <div className="featured-card">
                                            <div className="vertical featured-text"><div className="rotate text">Featured</div></div>
                                            <PostThumbnail post={data.posts.posts[0]} featured={true} />
                                        </div>
                                        <div className="bg-bottom"></div>
                                    </div>
                                }
                                <div className="mansory-wrap">
                                    <div className="vertical featured-text"><div className="rotate text">Articles</div></div>
                                    <div
                                        className="mansory"
                                    >
                                        {
                                            pageIndex === 1 ?
                                                data.posts.posts.slice(1).map(post => <PostThumbnail key={post._id} post={post} />)
                                                : data.posts.posts.map(post => <PostThumbnail key={post._id} post={post} />)
                                        }
                                    </div>
                                </div>
                                <div className="page-wrap">
                                    <Pagination current={pageIndex} pageSize={pageSize} onChange={this.handlePgaeChange} total={data.posts.totalCount} />
                                </div>
                            </>);
                        }}
                    </Query>
                </section>
            </PageLayout>
        );
    }
}

/*
<Mansory
                                        className="mansory"
                                        enableResizableChildren={true}
                                        disableImagesLoaded={false}
                                        updateOnEachImageLoad={true}
                                    >
                                        {
                                            pageIndex === 1 ?
                                                data.posts.posts.slice(1).map(post => <PostThumbnail key={post._id} post={post} />)
                                                : data.posts.posts.map(post => <PostThumbnail key={post._id} post={post} />)
                                        }
                                    </Mansory>*/

export default Home;