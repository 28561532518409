import React from 'react';

interface Props {
    level: number,
    children?: any
}

function flatten(text: string, child: any): any {
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text)
}

//export default Heading;

const Heading: React.FC<Props> = (props: Props) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    
    return React.createElement('h' + props.level, { id: text }, props.children)
};

export default Heading;