import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { ApolloProvider } from 'react-apollo';
import client from '../../graphql';
import gql from 'graphql-tag';
import Home from '../home/Home';
import Post from '../post/Post';
import Editor from '../editor/Editor';
import Auth from '../auth/Auth';
import Tags from '../tags/Tags';
import './App.scss';

const VERIFY = gql`
  mutation {
    verify {
      token,
      user {
        _id,
        username,
        email,
        role
      }
    }
  }
`;

class App extends React.PureComponent {

    async componentDidMount() {
        try {
            const res = await client.mutate({
                mutation: VERIFY,
                update: (caches, { data }) => {
                    caches.writeData({
                        data: {
                            me: { ...data.verify.user, isLoggedIn: true }
                        }
                    });
                }
            });
            localStorage.setItem('token', res.data.verify.token);
        } catch (err) {
            localStorage.removeItem('token');
        }
    }

    render() {
        return (
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <Route exact path="/" component={Home} />
                    <Route path="/editor" component={Editor} />
                    <Route path="/auth" component={Auth} />
                    <Route path="/tags" component={Tags} />
                    <Route path="/tags/:tag" component={Tags} />
                    <Route path="/post/:id" component={Post} />
                </BrowserRouter>
            </ApolloProvider>
        );
    }
}

/*
          <Route path="/post" component={Post} />*/
export default App;
