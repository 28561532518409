import React from 'react';
import { Comment, Avatar } from 'antd';
import CommentForm from './CommentForm';
import './Comment.scss';


export interface CommentData {
    _id: string
    text: string
    createdAt: string
    post: {
        _id: string
    }
    author: {
        _id: string
        username: string
    }
    replyto: CommentData | null
}


interface Props {
    comment: CommentData
    addComment: (comment: CommentData) => void
}

interface State {
    showForm: boolean
}

class CustomizedComment extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showForm: false
        };
    }

    toggleForm = () => {
        this.setState(state => ({ showForm: !state.showForm }));
    }

    render() {
        const { comment, addComment } = this.props;
        const { showForm } = this.state;

        return (
            <div className="comment" id={comment._id}>
                <Comment
                    actions={[
                        <span onClick={this.toggleForm}>Reply</span>
                    ]}
                    author={comment.author.username}
                    datetime={new Date(parseInt(comment.createdAt)).toDateString()}
                    avatar={<Avatar icon="user" size="large" />}
                    content={
                        <div className="comment-content">
                            {
                                comment.replyto &&
                                <blockquote>
                                    <span className="quoted-author"><a href={`#${comment.replyto._id}`}>@{comment.replyto.author.username}</a>: </span>
                                    <span>{comment.replyto.text}</span>
                                </blockquote>
                            }
                            <p>{comment.text}</p>
                        </div>
                    }
                >
                </Comment>
                {
                    showForm &&
                    <CommentForm
                        className="reply-form animated fadeIn"
                        postId={comment.post._id}
                        replyto={comment._id}
                        addComment={addComment}
                        toggleForm={this.toggleForm}
                    />
                }
            </div>
        );
    }
};


export default CustomizedComment;