import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Spin, Anchor, message } from 'antd';
//import Loader from 'react-loader-spinner';
import Loader from '../loader';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PageLayout from '../pageLayout/PageLayout';
import Content from './Content';
import CommentSection from '../commentSection/CommentSection';
import './Post.scss';

const GET_POST = gql`
    query getPost($id: ID!){
        post(id: $id){
            _id
            title
            tags
            summary
            content
            status
            cover
            createdAt
            updatedAt
            author{
                username
            }
            next {
                _id,
                title
            }
            prev{
                _id,
                title
            }
        }
    }
`;

interface PostData {
    post: {
        _id: string
        title: string
        tags: string[]
        summary: string
        content: string
        status: string
        cover: string
        createdAt: string
        updatedAt: string
        author: {
            username: string
        },
        prev: {
            _id: string,
            title: string
        },
        next: {
            _id: string,
            title: string
        }
    }
}

interface PostVariables {
    id: string
}

interface Props extends RouteComponentProps {
    children?: any
}

interface State {
    toc: any[]
}

interface Post {
    articleNode?: HTMLElement | null
}

class Post extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            toc: []
        };
    }

    setToc = (toc: any) => this.setState({ toc });

    render() {
        const { match } = this.props;
        const { toc } = this.state;

        return (
            <PageLayout currentPage="post">

                <section className="sec-post">
                    <Query<PostData, PostVariables>
                        query={GET_POST}
                        variables={match.params as PostVariables}
                    >
                        {({ loading, error, data }) => {
                            if (loading) return <Spin className="spin-wrap" indicator={<Loader type="Bars" color="#eb8a63" height="100" width="100" />} />;

                            if (error) {
                                console.log(error.networkError);
                                return message.error("Failed to fetch the post!");
                            }

                            if (data) return (<>
                                <div className="toc-wrap">
                                    <h2>Table of Contents</h2>
                                    <Anchor>{toc}</Anchor>
                                </div>
                                <div className="post-wrap">
                                    <article className="article" ref={(ref) => this.articleNode = ref}>
                                        <div className="post-header">
                                            <time className="pub-date">{new Date(parseInt(data.post.createdAt)).toDateString()}</time>
                                            <h1 className="title">{data.post.title}</h1>
                                            <p className="author">{data.post.author.username}</p>
                                            <div className="poster">
                                                <img className="bg" src={data.post.cover} alt="" />
                                            </div>
                                        </div>
                                        <Content content={data.post.content} setToc={this.setToc} />
                                        <ul className="tags">
                                            {data.post.tags.map(tag =>
                                                <li key={tag} className="tag">
                                                    <Link to={`/tags/${tag}`}>{tag}</Link>
                                                </li>
                                            )}
                                        </ul>
                                        <div className="post-author">
                                            <img src="http://img0.imgtn.bdimg.com/it/u=3740219286,3000853066&fm=26&gp=0.jpg" alt="" className="avatar" />
                                            <h3 className="author-name">Pomevak</h3>
                                            <p>Junior student at Tongji University, studying software engineering.</p>
                                            <div className="author-meta">
                                            </div>
                                        </div>
                                    </article>
                                    <div className="redirect">
                                        {
                                            data.post.prev &&
                                            <div className="prev">
                                                <span>Older Article</span>
                                                <h3><Link to={`/post/${data.post.prev._id}`}>{data.post.prev.title}</Link></h3>
                                            </div>
                                        }
                                        {data.post.prev && data.post.next && <div className="divider"></div>}
                                        {
                                            data.post.next &&
                                            <div className="next">
                                                <span>Newer Article</span>
                                                <h3><Link to={`/post/${data.post.next._id}`}>{data.post.next.title}</Link></h3>
                                            </div>
                                        }
                                    </div>
                                    <CommentSection postId={data.post._id} />
                                </div>
                            </>);
                        }}
                    </Query>
                </section>

            </PageLayout>
        );
    }
}

export default Post;