import React from 'react';
import { Link } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import RainBros from './RainBros';
import PageLayout from '../pageLayout/PageLayout';
import './Auth.scss';


interface Props {
    classes: any,
    history: History
}

interface State {
    currentTab: string
}


class Auth extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            currentTab: 'login'
        };
    }

    setTab = (tab: string) => this.setState({ currentTab: tab });

    render() {
        const { history } = this.props;
        const { currentTab } = this.state;

        return (
            <PageLayout currentPage="auth">
                <section className="sec-auth">
                    <RainBros></RainBros>
                    <div className="auth-wrap">
                        <div className="auth-intro animated lightSpeedIn">
                            <h1><Link to='/'>Pomevak</Link></h1>
                            <h2>Welcome To My Blog. Have Fun!</h2>
                        </div>
                        <div className={(currentTab === "login" ? "" : "turnaround") + " auth"}>
                            <Signup setTab={this.setTab} />
                            <Login setTab={this.setTab} history={history} />
                        </div>
                    </div>
                </section>
            </PageLayout>
        );
    }
}

export default Auth;