import React from 'react';
import { Avatar, Comment as AntComment, message, Spin } from 'antd';
// import Loader from 'react-loader-spinner';
import Loader from '../loader';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Comment, { CommentData } from '../comment/Comment';
import CommentForm from '../comment/CommentForm';
import './CommentSection.scss';

const GET_COMMENTS = gql`
    query getComments($postId: ID!) {
        comments(postId: $postId) {
            _id
            text
            createdAt
            post {
                _id
            }
            author {
                _id
                username
            }
            replyto {
                _id
                text
                author {
                    _id
                    username
                }
            }
        }
    }
`;

interface Props {
    postId: string
}


interface State extends CommentsData {

}

interface CommentsData {
    comments: CommentData[]
}

interface CommentsVariables {
    postId: string
}

class CommentSection extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            comments: []
        }
    }

    addComment = (comment: CommentData) => {
        this.setState((state) => ({ comments: [...state.comments, comment] }));
    }

    handleCompleted = (data: CommentsData) => {
        this.setState({ comments: data.comments });
    }

    render() {
        const { postId } = this.props;
        const { comments } = this.state;

        return (
            <section className="sec-comment">
                <AntComment
                    className="sec-comment-form"
                    avatar={<Avatar icon="user" size="large" />}
                    content={<CommentForm postId={postId} addComment={this.addComment} />}
                />
                <div className="comments">
                    <Query<CommentsData, CommentsVariables>
                        query={GET_COMMENTS}
                        variables={{ postId }}
                        onCompleted={this.handleCompleted}
                    >
                        {({ loading, error }) => {
                            if (loading)  return <Spin className="spin-wrap" indicator={<Loader type="Bars" color="#eb8a63" height="100" width="100" />} />;

                            if (error) return message.error("Failed to fetch comments!");

                            return null;
                        }}
                    </Query>
                    <h2>{comments.length} Comments</h2>
                    {comments.map(comment =>
                        <Comment key={comment._id} comment={comment} addComment={this.addComment} />)}
                </div>
            </section>
        );
    }
}


export default CommentSection;