import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Query, Mutation, } from 'react-apollo';
import gql from 'graphql-tag';
import './PageLayout.scss';

const GET_ME = gql`
    query getMe @client {
        me {
            isLoggedIn,
            role
        }
    }
`;

const LOG_OUT = gql`
    mutation logOut {
        logOut @client
    }
`;

interface Props {
    currentPage: string
    children?: any
}

interface State {
    showMenu: boolean
}

interface Data {
    me: {
        isLoggedIn: boolean
        role: string
    }
}

class Layout extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showMenu: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggleMenu = () => this.setState((state) => ({ showMenu: !state.showMenu }));

    render() {
        const { children } = this.props;
        const { showMenu } = this.state;

        return (
            <>
                <header className="header-wrap">
                    <div className="left">
                        <div className="logo">
                            <Link className="logo-image" to="/">P</Link>
                        </div>
                    </div>
                    <div className="right">
                        <nav className="nav-wrap">
                            <label htmlFor="toggle" className="nav-wrap--label hamburger hamburger--minus">
                                <span className={showMenu ? "hamburger close" : "hamburger"}>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                            </label>
                            <input type="checkbox" id="toggle" className="nav-wrap--toggle" onChange={this.toggleMenu} />
                            <ul className="nav-wrap--list">
                                <li className="nav-wrap--list-item"><NavLink to='/' exact={true}>Home</NavLink></li>
                                <li className="nav-wrap--list-item"><NavLink to='/tags' exact={true}>Tag Archives</NavLink></li>
                                <Query<Data> query={GET_ME}>
                                    {({ data }) => {
                                        if (data && data.me && data.me.isLoggedIn) {
                                            return (<>
                                                {
                                                    data.me.role === 'ADMIN' &&
                                                    <li className="nav-wrap--list-item" key="editor"><Link to='/editor'>Editor</Link></li>
                                                }
                                                <Mutation<any, any> mutation={LOG_OUT}>
                                                    {(logOut) => {
                                                        return (<li className="nav-wrap--list-item" key="auth"><Link to="/auth" onClick={() => logOut()}>Log out</Link></li>);
                                                    }}
                                                </Mutation>
                                            </>);
                                        }

                                        return <li className="nav-wrap--list-item" key="auth"><NavLink to='/auth' exact={true}>Log in / Sign up</NavLink></li>;
                                    }}
                                </Query>
                            </ul>
                        </nav>
                    </div>
                </header>
                <main className="main-wrap">
                    {children}
                </main>
                <footer className="footer-wrap">
                    <div className="footer-copyright">
                        Copyright © 2019, <Link to="/">Pomevak</Link>. All Right Reserved.<br />
                    </div>
                </footer>
            </>
        );
    }
}

export default Layout;