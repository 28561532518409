import React from 'react';
import { Tag, Spin, Empty, message } from 'antd';
//import Loader from 'react-loader-spinner';
import Loader from '../loader';
import gql from 'graphql-tag';
import ApolloClient from 'apollo-boost';
import { Query, withApollo } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import Mansory from 'react-masonry-component';
import { RouteComponentProps } from 'react-router-dom';
import PageLayout from '../pageLayout/PageLayout';
import PostThumbnail from '../postThumbnail/PostThumbnail';
import './Tags.scss';

const ALL_TAGS = gql`
    query {
        allTags {
            name
        }
    }
`;

const GET_POSTS_BY_TAGS = gql`
    query postsByTags($tags: [String]!) {
        postsByTags(tags: $tags) {
            _id
            title
            summary
            createdAt
            cover
            author {
                username
            }
        }
    }
`;

interface Props extends RouteComponentProps {
    client: ApolloClient<InMemoryCache>
}

interface State {
    tags: {
        _id: string
        name: string
    }[]
    selectedTags: string[]
    loading: boolean
}

interface TagsData {
    allTags: {
        _id: string
        name: string
    }[]
}

interface PostsData {
    postsByTags: {
        _id: string
        title: string
        summary: string
        createdAt: string
        cover: string
        author: {
            username: string
        }
    }[]
}

interface PostsVariables {
    tags: string[]
}

class Tags extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        const { match } = this.props;

        this.state = {
            tags: [],
            selectedTags: (match.params as any).tag ? (match.params as any).tag : [],
            loading: true
        }
    }

    async componentDidMount() {
        const { client } = this.props;
        const { selectedTags } = this.state;

        try {
            const res = await client.query<TagsData>({ query: ALL_TAGS });
            if (res.data.allTags.length && !selectedTags.length) {
                this.setState({
                    tags: res.data.allTags,
                    selectedTags: [res.data.allTags[0].name],
                    loading: false
                });
            } else {
                this.setState({
                    tags: res.data.allTags,
                    loading: false
                });
            }
        } catch (err) {
            message.error("Failed to fetch tags!");
        }
    }

    handleChange(tag: string, checked: boolean) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        this.setState({ selectedTags: nextSelectedTags });
    }

    handleTagsFetched = (data: TagsData) => {
        this.setState({
            tags: data.allTags,
            selectedTags: [data.allTags[0].name]
        });

        console.log(this.state);
    }

    render() {
        const { tags, selectedTags, loading } = this.state;
        return (
            <PageLayout currentPage="tags">
                <section className="sec-tags">
                    {
                        loading ? <Spin className="spin-wrap" indicator={<Loader type="Bars" color="#eb8a63" height="100" width="100" />} /> :
                            <>
                                <div className="tags-wrap">
                                    {tags.map(tag => (
                                        <Tag.CheckableTag
                                            key={tag.name}
                                            checked={selectedTags.indexOf(tag.name) > -1}
                                            onChange={checked => this.handleChange(tag.name, checked)}
                                        >
                                            {tag.name}
                                        </Tag.CheckableTag>
                                    ))}
                                </div>
                                <div className="results-wrap">
                                    {
                                        selectedTags.length > 0 &&
                                        <Query<PostsData, PostsVariables>
                                            query={GET_POSTS_BY_TAGS}
                                            variables={{ tags: selectedTags }}
                                        >
                                            {
                                                ({ loading, error, data }) => {
                                                    if (loading) return <Spin className="spin-wrap" indicator={<Loader type="Bars" color="#eb8a63" height="100" width="100" />} />;

                                                    if (error) return message.error("Failed to fetch posts!");

                                                    if (data && data.postsByTags.length > 0) {
                                                        return (
                                                            <div
                                                                className="masonry"
                                                            >
                                                                {data.postsByTags.map(post => <PostThumbnail key={post._id} post={post} />)}
                                                            </div>
                                                        );
                                                    } else {
                                                        return <Empty />;
                                                    }
                                                }
                                            }
                                        </Query>
                                    }
                                </div>

                            </>
                    }
                </section>
            </PageLayout>
        );
    }
}

export default withApollo(Tags);