import React from 'react';
import { Form, Icon, Input, Button, Checkbox, Avatar, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import gql from "graphql-tag";
import { Query, Mutation, MutationFn, MutationUpdaterFn } from "react-apollo";

const LOG_IN = gql`
    mutation Login($email: String!, $password: String!){
        login(email: $email, password: $password){
            token,
            user{
                _id,
                email,
                username,
                role
            }
        }
    }
`;

const GET_ME = gql`
    query getMe @client {
        me {
            email
        }
    }
`;

interface Props extends FormComponentProps {
    setTab: (tab: string) => void,
    history: any
}


interface State {
}

interface Data {
    login: {
        user: any,
        token: string
    }
};

interface Variables {
    email: string,
    password: string
};


class Login extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
    }

    gotoSignup = (ev: React.MouseEvent) => {
        ev.preventDefault();
        this.props.setTab('signup');
    }

    handleSubmit = (login: MutationFn<Data, Variables>) => (ev: React.FormEvent) => {
        ev.preventDefault();

        const { getFieldsValue } = this.props.form;
        login({ variables: getFieldsValue(['email', 'password']) as Variables })
    }

    handleLoginCompleted = (data: Data) => {
        const { history } = this.props;
        localStorage.setItem('token', data.login.token);
        history.push('/');
    }

    updateCache: MutationUpdaterFn<Data> = (caches, { data }) => {
        caches.writeData({
            data: {
                me: { ...(data as Data).login.user, isLoggedIn: true }
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="login">
                <Avatar className="logo" size={64} icon="user" />
                <h1>Sign in</h1>
                <Mutation<Data, Variables>
                    mutation={LOG_IN}
                    onCompleted={this.handleLoginCompleted}
                    update={this.updateCache}
                >
                    {
                        (login, { loading, error }) => {

                            if (error) return message.error("Username or password is incorrect!");

                            return (
                                <Form className="login-form" onSubmit={this.handleSubmit(login)}>
                                    <Query<any> query={GET_ME}>
                                        {({ data }) => {
                                            return (
                                                <Form.Item>
                                                    {getFieldDecorator('email', {
                                                        rules: [{ required: true, message: 'Please input your email!' }],
                                                        initialValue: data && data.me ? data.me.email : ''
                                                    })(
                                                        <Input
                                                            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                            size="large" placeholder="Email" type="email"
                                                        />
                                                    )}
                                                </Form.Item>
                                            );
                                        }}
                                    </Query>
                                    <Form.Item>
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Please input your Password!' }],
                                        })(
                                            <Input
                                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                size="large" type="password" placeholder="Password"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="form-actions">
                                            {getFieldDecorator('remember', {
                                                valuePropName: 'checked',
                                                initialValue: true,
                                            })(
                                                <Checkbox>Remember me</Checkbox>
                                            )}
                                        </div>
                                        <Button type="primary" htmlType="submit" className="form-button" loading={loading}>
                                            Log in
                                        </Button>
                                        <div className="form-actions">
                                            No account yet? <Button type="link" onClick={this.gotoSignup}>Register Now!</Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            );
                        }
                    }
                </Mutation>
            </div>
        );
    }
}


export default Form.create<Props>({ name: 'Login' })(Login);