import gql from 'graphql-tag';

const typeDefs = gql`
    extend type Query {
        me: User
    }

    extend type Mutation {
        logout: User
    }

    extend type User {
        isLoggedIn: Boolean
    }
`;

export default typeDefs;