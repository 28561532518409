import React from 'react';
import './RainBros.scss';

const RainBros: React.FC = ()=>{
    return(
        <div id="holder" className="animated slideInLeft">
  <div id="viewport">
    <div className="subviewport expl">
      <div id="explosion">
        <div id="explosion-circle" />
      </div>
    </div>
    <div className="subviewport red">
      <div className="circles">
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top" />
        <div className="circle-top"> </div>
      </div>
    </div>
    <svg
      version="1.1"
      id="red-legs"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="239.189px"
      height="213.698px"
      viewBox="0 0 239.189 213.698"
      enableBackground="new 0 0 150 150"
      xmlSpace="preserve"
    >
      <path
        fill="none"
        stroke="#333333"
        strokeWidth={2}
        d="M148.126,179.477c64-17.334,4.667-27.333,2,16"
      >
        <animate
          attributeName="d"
          begin="0s"
          dur="0.96s"
          repeatCount="indefinite"
          values="M148.126,179.477c64-17.334,4.667-27.333,2,16;
                   M137.493,179.477c61.5-13.898,15.681-24.738,8,16;
                   M125.493,162.989c60.25,16.182,11.25-11.5,18,32.487;
                   M125.493,146.489c38.75,24.494,14.75,13.5,14.75,48.987;
                   M125.493,133.239c12.932,16.25,18.5,26.75,4,62.237;
                   M125.493,120.739c46,17.25,17.25,48.5-9,48.5;
                   M122.743,113.489c52.25,8,21.25,54.75,6.461,54.75;
                   M122.743,106.849c63.25,5.39,28,61.39,16.929,61.39;
                   M122.743,106.849c49.25,0,41.665,42.391,26.587,56.412;
                   M124.743,103.739c44.75,0,41.747,36.359,33.055,54.055;
                   M124.743,100.739c48.25,0,41.93,28.484,41.93,50.18;
                   M124.743,100.739c48.25,0,50.108,24.09,54.25,48.18;
                   M124.743,100.739c60,0,46.5,13.75,67.25,44;
                   M124.743,99.739c31.5,31.5,63.25,7.11,72.25,45;
                   M124.743,99.739c21.602,37.11,25.852,49.86,65.352,49.86;
                   M124.743,99.739c14.602,36.86,38.852,53.36,65.352,71.36;
                   M126.664,106.849c4.632,12.079,41.931,58.5,63.431,84;
                   M125.505,112.099c11.84,11.5,46.84,46.75,57.84,80;
                   M133.094,117.099c11.84,11.5,39.5,34.25,42.5,75;
                   M133.094,124.349c12.951,9.631,38,16,38,69.75;
                   M130.344,135.099c64,29.5,18.875,20.5,37.75,59;
                   M124.844,147.365c78.5,15.734,16.375,8.234,35.25,46.734;
                   M141.844,159.599c52,6,10.25-5.978,14.069,34.5;
                   M147.094,168.349c59.25-6.143,2.5-14.5,7,25.75
                   "
        />
      </path>
      <path
        fill="none"
        stroke="#333333"
        strokeWidth={2}
        d="M78.559,134c64-17.334,4.667-27.333,2,16"
      >
        <animate
          attributeName="d"
          begin="0s"
          dur="0.96s"
          repeatCount="indefinite"
          values="M80.126,156.81c-20.667,28.667-8.667,37.999,22,29.333;
                   M66.993,195.477c46,0,42.292-11.696,46-15.403;
                   M74.753,195.354c46,0,47.031-28.657,50.739-32.364;
                   M81.243,195.477c46,0,52-24.556,44.25-48.987;
                   M103.517,186.74c53.726-3.75,30.745-34.627,18.976-53.5;
                   M116.493,195.477c21.592-34.487,17.096-50.688,9-74.737;
                   M106.993,195.477c29.5-36.237,26.149-55.987,15.75-81.987;
                   M94.493,195.477c34.711-27.237,38.649-62.627,28.25-88.627;
                   M85.993,195.477c22.75-30.487,38.75-60.386,38.75-88.627;
                   M76.993,195.477c22.75-30.487,36-68.487,47.75-94.737;
                   M69.243,177.99c32-23,55.5-55.75,55.5-77.25;
                   M68.243,158.74c39-4.5,56.5-36.5,56.5-58;
                   M69.243,146.24c32,9,55.5-24,55.5-45.5;
                   M69.243,143.24c33.75,11.5,59-20.5,55.5-42.5;
                   M69.243,138.849c30.852,19.25,59-16.109,55.5-38.11;
                   M71.094,135.419c29.5,23.68,58.5-9.82,53.648-34.68;
                   M72.594,133.349c27.034,23.625,52.375,4.75,54.067-27.5;
                   M72.594,128.696c17,33.653,52.908,14.153,52.91-16.597;
                   M72.594,124.849c14,37.25,51.908,24,51.91-6.75;
                   M74.344,127.599c0,35.5,46.75,34.75,49.16-1.5;
                   M75.344,133.599c-2.5,36.5,44.75,36.75,47.16,0.5;
                   M75.259,137.373c-9.915,37.477,42.675,45.242,45.085,8.992;
                   M78.094,142.599c-12.75,31.5,19.75,45.25,35,19.5;
                   M78.094,151.349c-11.25,21-1.25,41.25,27.5,24.218;"
        />
      </path>
    </svg>
    <div className="hexagon dude" style={{}}>
      <div className="face"> </div>
    </div>
    <div className="subviewport orange">
      <div className="circles">
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top" />
        <div className="circle-top"> </div>
      </div>
    </div>
    <svg
      version="1.1"
      id="orange-legs"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="239.189px"
      height="213.698px"
      viewBox="-30 0 239.189 213.698"
      enableBackground="new 0 0 150 150"
      xmlSpace="preserve"
    >
      <path
        fill="none"
        stroke="#333333"
        strokeWidth={2}
        d="M-17.904,195.477c41.333-36.127,36-59.46,45.333-80.127"
      >
        <animate
          attributeName="d"
          begin="0.12s"
          dur="1.44s"
          repeatCount="indefinite"
          values="M-17.904,195.477c41.333-36.127,36-59.46,45.333-80.127;
                   M-21.906,195.477c42.25-32.377,41.687-57.056,49.335-80.127;
                   M-24.906,194.1c42.75-27.75,45.546-53.48,52.335-76.75;
                   M-24.906,194.1c42.75-27.75,43.068-44.52,49.857-67.789;
                   M-28.906,195.35c42.25-25,44.25-33.25,52.5-58;
                   M-35.906,194.35c30.065-8.45,47.75-16.5,58.029-42.744;
                   M-44.656,194.35c30,0,46.5,3,65.5-16.5;
                   M-34.656,186.35c30,0,36.263,1.735,49.49-8.916;
                   M-26.406,177.1c30,0,28.25,3,38.75-5.25;
                   M-16.156,169.35c30,0,27.536,0.544,37.792-5.166;
                   M-7.694,161.773c30,0,54.289-7.674,46.289-26.924;
                   M1.973,154.062c32.622,0,50.622-8.674,42.622-27.924;
                   M10.204,158.273c32.89,1.076,50.64-19.337,30.14-38.674;
                   M18.844,162.85c32.89,1.076,43-30.5,20.25-45.25;
                   M26.094,166.85c33.75-4.25,35.75-37.75,12-51.25;
                   M28.844,168.1c30.25-4.75,32.5-42,9.25-52.5;
                   M34.094,169.6c27-5.25,27.5-47.5-2-55;
                   M43.594,175.35c20-18,16.75-55.25-14.75-59.25;
                   M53.344,182.1c13.25-25.25,6.542-58.25-24.5-63.875;
                   M58.594,186.183c5.093-25-2.5-58.333-31.167-64.5;
                   M62.928,188.35c0.969-25.167-10.613-49.714-30-60.333;
                   M70.928,194.85c-15.667-26.833-30.333-41.5-42.5-60.333;
                   M61.261,194.85c-4-30.167-8.333-31.667-21.833-48.667;
                   M53.761,194.85c0.667-43.833-3-5.833-28.333-37.5;
                   M47.219,193.975c-8.125-48.75,28.25-1-15.125-21.125;
                   M45.25,193.975c-7.655-42.625,21.97-10.875-7.655-21.375;
                   M39.344,193.975c-7.125-30.375,17-22.875-1.75-30.375;
                   M30.719,193.975c-4.125-23.625,15.875-32.5,4.5-42.5;
                   M-4.656,165.1c44.25,7.375,52-19.25,39.113-27.375;
                   M5.844,153.975c44.25,7.375,52-17.75,31-27.25;
                   M12.344,156.475c43.25,5.25,45.5-28.625,20.875-35;
                   M18.719,159.225c42.75-3.125,37.375-37,13-40.5;
                   M24.469,162.475c35.75-6.625,31.875-42.375,9.5-46.875;
                   M30.928,165.017c31-9.792,26.167-44.334,6.84-51.167;
                   M39.928,170.683c18.251-11.833,19.167-44.333-4.16-57.833;
                   M49.094,177.017c9.803-18.454,8.5-50.334-13.326-64.167;
                   M58.094,182.85c3.129-25.685-1.833-57-25.167-70.333;
                   M67.428,190.183c-2.167-25.833-21.424-66.333-41.833-77.666;"
        />
      </path>
      <path
        fill="none"
        stroke="#333333"
        strokeWidth={2}
        d="M27.429,113.85c25.415,25.25,32.665,37,40.165,77.25"
      >
        <animate
          attributeName="d"
          begin="0.12s"
          dur="1.44s"
          repeatCount="indefinite"
          values="M27.429,113.85c25.415,25.25,32.665,37,40.165,77.25;
                   M27.429,113.85c17.415,28.25,31.165,39.5,42.915,79;
                   M29.429,118.1c18.024,31.027,22.165,39.25,42.665,74.75;
                   M30.594,128.1c13.25,33.5,15.25,32.375,41.5,64.75;
                   M27.594,135.6c11.5,29.25,41.5,41.75,35.5,58.5;
                   M32.594,158.85c13.122,28.5,34.25,3.75,21.25,35.25;
                   M29.844,172.978c34.5,40.722,31.25-28.628,15.224,20.122;
                   M37.094,170.35c27.75,21.334,11.5-14.75,1.964,21.334;
                   M38.844,163.85c10.5,15.125,0,0-7.5,30.25;
                   M34.344,152.6c8,14.22-1.75,14.22-10,41.5;
                   M31.097,139.6c1.891,15.283-4.503,27.22-12.753,54.5;
                   M28.094,130.35c-4.472,15.25-8.043,36.47-16.293,63.75;
                   M28.094,122.6c-1.126,3.839-8.691,32.453-20.5,71.5;
                   M25.844,117.6c-1.126,3.839-11.119,37.418-22.927,76.465;
                   M24.094,115.1C22.969,118.938,11.547,155.6-1,194.064;
                   M24.094,115.1C22.969,118.938,10.547,154.6-2,193.064;
                   M24.094,115.1C22.969,118.938,10.547,154.6-2,193.064;
                   M24.094,115.1c-1.126,3.839-11,43-28,79;
                   M23.261,118.225C21.838,130.457,9.928,171.017-8.072,194.1;
                   M25.428,119.85c-1.833,15.333-15.833,51.833-35.667,74.25;
                   M24.116,126.688c-1.833,15.333-16.355,50.829-39.021,67.412;
                   M23.428,132.517c-1.833,15.333-19.167,44.75-41.833,61.333;
                   M22.761,141.183c-7.833,27.834-24.833,42-47.667,53.667;
                   M22.761,152.85c-13.5,28.333-33.333,40.5-53.667,42;
                   M22.761,176.475c-24.667,21-38.167,18.756-57.417,18.756;
                   M13.566,182.473C0,192.162-11.156,193.6-31.656,192.35;
                   M14.094,178.225c-11.75,9.5-18.5,7.625-39,6.375;
                   M34.457,152.85c0,8.375-12.613,30.375-49.863,21.875;
                   M30.344,140.35c0.75,9.875-5.625,29.875-9.5,53.375;
                   M21.469,138.1c0.75,9.875-4.875,32.125-8.75,55.625;
                   M22.594,127.85c0.039,9.625-9.625,42.375-13.5,65.875;
                   M23.344,121.475c0.039,9.625-13.625,48.75-17.5,72.25;
                   M23.344,116.725c0.037,9.146-16.417,52.792-21.25,77;
                   M23.344,114.517c-0.75,9-18.583,56.307-24.917,79.208;
                   M24.594,112.517c-0.75,9-16.833,60-29.833,81.208;
                   M24.594,112.517c-2.167,23.5-17.583,65.833-35.167,81.208;
                   M25.594,112.517c-1.167,23-15.5,64.5-40.333,81.208;
                   M25.594,112.517c-1.167,23-16,65.333-43.5,81.166;"
        />
      </path>
    </svg>
    <div className="box dude" style={{}}>
      <div className="face" style={{}} />
    </div>
    <div className="subviewport green" style={{}}>
      <div className="circles">
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top" />
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
      </div>
    </div>
    <div className="rectangle dude">
      <div className="face"> </div>
    </div>
    <div className="subviewport blue" style={{}}>
      <div className="circles">
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top"> </div>
        <div className="circle-top" />
        <div className="circle-top" />
        <div className="circle-top"> </div>
      </div>
    </div>
    <div className="circle dude">
      <div className="face"> </div>
    </div>
  </div>
</div>

    );
}

export default RainBros;