import React from 'react';
import MarkdownRender from "@nteract/markdown";
import { Anchor, Button } from 'antd';
import Heading from './Heading';
import CodeBlock from './CodeBlock';

interface Props {
    content?: string
    children?: any
    setToc: (toc: any) => void
}

interface Content {
    contentNode?: HTMLElement | null
}

const LinkButton: React.FC<Props> = (props: Props) => {
    return <Button type="link">{props.children}</Button>
};

class Content extends React.PureComponent<Props> {

    componentDidMount() {
        let links: any[][] = [[], [], [], [], []];

        if (this.contentNode) {
            const headings = Array.from(this.contentNode.children).reduce((prev: { node: Element, level: number }[], cur) => {
                if (cur instanceof HTMLHeadingElement) {
                    const level = parseInt(cur.tagName.substring(1));
                    level < 5 && prev.push({ node: cur, level });
                }
                return prev;
            }, []);

            for (let i = headings.length - 1; i > -1; --i) {
                if (i === headings.length - 1) {
                    links[headings[i].level].unshift(
                        <Anchor.Link key={headings[i].node.id} href={'#' + headings[i].node.id} title={headings[i].node.id} />);
                    continue;
                }

                if (headings[i].level < headings[i + 1].level) {
                    console.log(i, headings[i].level);
                    links[headings[i].level].unshift(
                        <Anchor.Link key={headings[i].node.id} href={'#' + headings[i].node.id} title={headings[i].node.id}>
                            {links[headings[i + 1].level]}
                        </Anchor.Link>
                    );
                    links[headings[i + 1].level] = [];
                } else if (headings[i].level === headings[i + 1].level) {
                    links[headings[i].level].unshift(
                        <Anchor.Link key={headings[i].node.id} href={'#' + headings[i].node.id} title={headings[i].node.id} />);
                } else {
                    links[headings[i + 1].level - 1].unshift(
                        <Anchor.Link key={headings[i].node.id} href={''} title={''}>
                            {links[headings[i + 1].level]}
                        </Anchor.Link>
                    );
                }
            }
        }

        for (const key in links) {
            if (links[key].length) this.props.setToc(links[key]);
        }
    }

    render() {
        const { content } = this.props;
        return (
            <div className="post-content" ref={ref => this.contentNode = ref}>
                <MarkdownRender
                    source={content}
                    renderers={{
                        heading: Heading,
                        code: CodeBlock,
                        link: LinkButton
                    }}
                />
            </div>
        );
    }
}

export default Content;