import ApolloClient from "apollo-client";
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import typeDefs from './schema';
import resolvers from './resolvers';

const httpLink = new HttpLink({
    uri: 'https://pomevak-blog-api.now.sh/graphql'
});

const authMiddleware = new ApolloLink((operation, forward: any) => {
    // add the authorization to the headers
    const token = localStorage.getItem('token');
    operation.setContext(({ headers = {} }: { headers: Object }) => ({
        headers: {
            ...headers,
            Authorization: token || null,
        }
    }));

    return forward(operation);
});

const cache = new InMemoryCache();

const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache,
    typeDefs,
    resolvers
});

cache.writeData({
    data: {
        me: null
    }
});

export default client;