import React from 'react';
import { Form, Icon, Input, Button, Avatar, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import gql from 'graphql-tag';
import { Mutation, MutationFn, MutationUpdaterFn } from 'react-apollo';


const SIGN_UP = gql`
    mutation signUp($email: String!, $username: String!, $password:String){
        signup(email: $email, username: $username, password: $password){
            token,
            user {
                _id,
                email
            }
        }
    }
`;

interface Props extends FormComponentProps {
    setTab: (tab: string) => void
}

interface State {

}

interface SignupData {
    signup: {
        token: string,
        user: {
            email: string
        }
    }
}

interface SignupVariables {
    email: string,
    username: string,
    password: string
}

class Signup extends React.PureComponent<Props, State>{

    gotoLogin = () => {
        this.props.setTab('login');
    }

    compareToFirstPassword = (rule: any, value: any) => {
        const { getFieldValue } = this.props.form;
        const password = getFieldValue('password');

        return value === password;
    }

    handleSubmit = (signup: MutationFn<SignupData, SignupVariables>) => (ev: React.FormEvent) => {
        ev.preventDefault();

        const { getFieldsValue } = this.props.form;
        signup({
            variables: getFieldsValue(['username', 'email', 'password']) as SignupVariables
        });
    }

    handleComplete = async () => {
        await message.success('Account created Successfully!');
        this.gotoLogin();
    }

    updateCache: MutationUpdaterFn<SignupData> = (caches, { data }) => {
        caches.writeData({
            data: {
                me: { ...(data as SignupData).signup.user, isLoggedIn: false }
            }
        });
    };

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className="signup">

                <Avatar className="logo" size={64} icon="user" />
                <h1>Sign up</h1>
                <Mutation<SignupData, SignupVariables>
                    mutation={SIGN_UP}
                    onCompleted={this.handleComplete}
                    update={this.updateCache}
                >
                    {(signUp, { loading, error }) => {
                        if (error) message.error('Sign up failed!');

                        return (
                            <Form className='signup-form' onSubmit={this.handleSubmit(signUp)}>
                                <Form.Item>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, message: 'Please input your username!' }],
                                    })(
                                        <Input
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            size="large" placeholder="Username"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            type: 'email', message: 'The input is not valid E-mail!',
                                        }, {
                                            required: true, message: 'Please input your E-mail!',
                                        }],
                                    })(
                                        <Input
                                            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            size="large" type="email" placeholder="Email"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            size="large" type="password" placeholder="Password"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('confirm', {
                                        rules: [{
                                            required: true, message: 'Please confirm your password!',
                                        }, {
                                            validator: this.compareToFirstPassword, message: 'Please input the same password!'
                                        }],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            size="large" type="password" placeholder="Confrim Password" />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="form-button" loading={loading}>Sign up</Button>
                                    <div className="form-actions">
                                        Already have an account? <Button type="link" onClick={this.gotoLogin}>Log in!</Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        );
                    }}
                </Mutation>

            </div>
        );
    }
}

export default Form.create<Props>({ name: 'Signup' })(Signup);