import React from 'react';
import { Link } from 'react-router-dom';
import './PostThumbnail.scss';

interface Props {
    post: {
        _id: string
        title: string
        cover: string
        summary: string
        createdAt: string
        author: {
            username: string
        }
    }
    featured?: boolean
}

interface State {
}

class PostThumbnail extends React.PureComponent<Props, State> {

    render() {
        const { post, featured } = this.props;

        return (
            <article className={(featured ? "featured" : "") + " post animated fadeInUp"}>
                <Link to={`/post/${post._id}`} className="cover">
                    <img src={post.cover} alt="cover" />
                </Link>
                <div className="content">
                    <h2 className="title"><Link to={`/post/${post._id}`}>{post.title}</Link></h2>
                    <p className="meta">
                        <span>Posted by {post.author.username} on <time>{new Date(parseInt(post.createdAt)).toDateString()}</time></span>
                    </p>
                    <p className="summary">{post.summary}</p>
                    <div className="actions">
                        <Link className="more-button" to={`/post/${post._id}`}>Read More</Link>
                    </div>
                </div>
            </article>
        )
    }
}


export default PostThumbnail;