import React from 'react';
import { RouterProps } from 'react-router';
import { Form, Input, Button, Select, Spin, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import MarkdownRender from "@nteract/markdown";
import ReactMde from "react-mde";
import { withApollo, Query, Mutation, MutationFn } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import PageLayout from '../pageLayout/PageLayout';
import "react-mde/lib/styles/css/react-mde-all.css";
import './Editor.scss';

const GET_ME = gql`
    query {
        me @client {
            _id
        }
    }
`;

const ALL_TAGS = gql`
    query {
        allTags {
            _id,
            name
        }
    }
`;

const CREATE_POST = gql`
    mutation createPost($title: String!, $author: String!, $tags: [String]!, $summary: String!, $content: String!, $status: String!){
        createPost(title: $title, author: $author, tags: $tags, summary: $summary, content: $content, status: $status){
            _id,
            title
        }
    }
`;

interface Props extends FormComponentProps, RouterProps {
    client: ApolloClient<InMemoryCache>
}

interface State {
    content: string
}

interface Tag {
    _id: string
    name: string
}

interface Me {
    _id: string
}

interface TagData {
    allTags: Tag[]
}

interface PostData {
    createPost: {
        _id: string,
        title: string
    }
}

interface PostVariables {
    title: string,
    author: string,
    tags: string[],
    summary: string,
    content: string,
    status: string
}

const children = [];
for (let i = 10; i < 36; i++) {
    children.push(<Select.Option key={i.toString(36) + i}>{i.toString(36) + i}</Select.Option>);
}


class Editor extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            content: "**Hello world!!!**"
        };
    }

    handleContentChange = (content: string) => {
        this.setState({ content });
    };

    handleChange = (value: string) => {
        console.log(`selected ${value}`);
    }

    handlePublishCompleted = async (data: PostData) => {
        const { history } = this.props;

        await message.success('Post Successfully Published!', 1);
        history.push(`/post/${data.createPost._id}`);
    }

    handleSubmit = (createPost: MutationFn<PostData, PostVariables>, status: string) => () => {
        const { getFieldsValue } = this.props.form;
        const { content } = this.state;
        const { client } = this.props;

        try {
            const data = client.readQuery<{ me: Me }>({ query: GET_ME });
            if (data === null) {
                throw new Error('Not Logged in');
            }

            createPost({
                variables: {
                    ...getFieldsValue(['title', 'tags', 'summary']),
                    author: data.me._id,
                    content,
                    status
                } as PostVariables
            })
        } catch (err) {
            console.error(err);
            message.info('Error: Publication Failed!')
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Mutation<PostData, PostVariables>
                mutation={CREATE_POST}
                onCompleted={this.handlePublishCompleted}
            >
                {(createPost, { loading, error }) => (
                    <Spin className="loading" tip="Publishing..." spinning={loading}>
                        <PageLayout currentPage="editor">
                            <section className="sec-editor">
                                <Form className="editor-form animated fadeIn slow">
                                    <div className="meta animated slideInUp">
                                        <div className="fields">
                                            <Form.Item>
                                                {getFieldDecorator('title', {
                                                    rules: [{ required: true, message: 'Please input the title!' }],
                                                })(<Input placeholder="Title" type="text" />)}
                                            </Form.Item>
                                            <Query<TagData> query={ALL_TAGS} >
                                                {
                                                    ({ data }) => {
                                                        return (
                                                            <Form.Item>
                                                                {getFieldDecorator('tags', {
                                                                    rules: [{ required: true, message: 'Please input tags!' }],
                                                                })(
                                                                    <Select mode="tags" style={{ width: '100%' }}
                                                                        placeholder="Tags" onChange={this.handleChange}
                                                                    >
                                                                        {
                                                                            data && data.allTags && data.allTags.map(tag =>
                                                                                <Select.Option key={tag.name} value={tag.name}>
                                                                                    {tag.name}
                                                                                </Select.Option>)
                                                                        }
                                                                    </Select>
                                                                )}
                                                            </Form.Item>
                                                        );
                                                    }
                                                }
                                            </Query>
                                            <Form.Item>
                                                {getFieldDecorator('summary', {
                                                    rules: [{ required: true, message: 'Please input the summary!' }],
                                                })(<Input.TextArea placeholder="Summary" autosize={{ minRows: 6 }} />)}
                                            </Form.Item>
                                        </div>
                                        <Button.Group className="form-actions">
                                            <Button size="large"
                                                onClick={this.handleSubmit(createPost, 'PUBLISHED')}
                                            >Publish</Button>
                                        </Button.Group>

                                    </div>
                                    <div className="raw animated slideInDown">
                                        <ReactMde
                                            className="raw-editor"
                                            onChange={this.handleContentChange}
                                            value={this.state.content}
                                        />
                                    </div>
                                    <div className="preview animated slideInUp">
                                        <MarkdownRender source={this.state.content} />
                                    </div>
                                </Form>
                            </section>
                            {
                                error && message.error('Publication Failed!')
                            }
                        </PageLayout >
                    </Spin>
                )}
            </Mutation>
        );
    }
}

export default Form.create<Props>({ name: 'CreatePost' })(withApollo(Editor));