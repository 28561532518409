import { Resolvers } from 'apollo-boost';

const resolvers: Resolvers = {
    Mutation: {
        logOut: (_root: any, variables: any, { cache }) => {
            cache.writeData({
                data: {
                    me: null
                }
            });
            localStorage.removeItem('token');
            return null;
        }
    }
};

export default resolvers;