import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/styles/hljs';

interface Props {
    language: string,
    value: string
}


export default class CodeBlock extends React.PureComponent<Props> {

    render() {
        const { language, value } = this.props;

        return (
            <SyntaxHighlighter
                style={docco}
                language={language}
                showLineNumbers={true}
                lineNumberContainerStyle={{
                    float: 'left',
                    paddingLeft: '.5rem',
                    paddingRight: '.5rem',
                    borderRight: '1px solid #ededed'
                }}
            >
                {value}
            </SyntaxHighlighter>
        );
    }
}