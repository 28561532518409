import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Mutation, MutationFn } from 'react-apollo';
import gql from 'graphql-tag';
import { CommentData } from './Comment';
import './CommentForm.scss';

const CREATE_COMMENT = gql`
    mutation createComment($post:String!, $email: String!, $displayName: String!, $text: String!, $replyto: String) {
        createComment(post: $post, email: $email, displayName: $displayName, text: $text, replyto: $replyto){
            _id
            text
            createdAt
            post {
                _id
            }
            author {
                _id
                username
            }
            replyto {
                _id
                text
                author {
                    _id
                    username
                }
            }
        }
    }
`;

interface Props extends FormComponentProps {
    postId: string
    className?: string
    replyto?: string | null,
    addComment: (comment: CommentData) => void
    toggleForm?: () => void
}

interface CreateCommentData {
    createComment: CommentData
}

interface CommentVariables {
    post: string
    email: string
    displayName: string
    text: string
    replyto?: string
}

class CommentForm extends React.PureComponent<Props> {

    handleSubmit = (createComment: MutationFn<CreateCommentData, CommentVariables>) => (ev: React.FormEvent) => {
        ev.preventDefault();

        const { postId, replyto } = this.props;
        const { getFieldsValue } = this.props.form;
        getFieldsValue(['email', 'displayName', 'text']);
        createComment({
            variables: {
                ...getFieldsValue(['email', 'displayName', 'text']),
                post: postId,
                replyto
            } as CommentVariables
        });
    }

    handleComplete = (data: CreateCommentData) => {
        message.success('Thanks for your comment!');
        this.props.addComment(data.createComment);
        const { toggleForm } = this.props;
        toggleForm && toggleForm();
    }

    render() {
        const { className, form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Mutation<CreateCommentData, CommentVariables>
                mutation={CREATE_COMMENT}
                onCompleted={this.handleComplete}
            >
                {
                    (createComment, { loading, error, data }) => {
                        return (
                            <Form className={"comment-form " + className} onSubmit={this.handleSubmit(createComment)}>
                                <div className="user-info">
                                    <Form.Item>
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'Please input the email!' }]
                                        })(<Input placeholder="Email" />)}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('displayName', {
                                            rules: [{ required: true, message: 'Please input the name for display!' }]
                                        })(<Input placeholder="Display Name" />)}
                                    </Form.Item>
                                </div>
                                <Form.Item>
                                    {getFieldDecorator('text', {
                                        rules: [{ required: true, message: 'Please input your thoughts!' }]
                                    })(<Input.TextArea rows={4} placeholder="What are your thoughts?" />)}
                                </Form.Item>
                                <Form.Item className="send-btn">
                                    <Button htmlType="submit" loading={loading}>Send</Button>
                                </Form.Item>
                            </Form>
                        );
                    }
                }
            </Mutation>
        );
    }
}

export default Form.create<Props>({ name: 'CommentForm' })(CommentForm);